import React, { useState } from 'react';
import Alert from "@awsui/components-react/alert";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Button from "@awsui/components-react/button";
import SpaceBetween from "@awsui/components-react/space-between";

const FileFormatGuidance = () => {
  const [showTips, setShowTips] = useState(false);
  const [showFormatDetails, setShowFormatDetails] = useState(false);
  const [showCommonIssues, setShowCommonIssues] = useState(false);

  return (
    <Container>
      <Alert
        type="info"
        header="Important: Excel File Format Requirements"
        action={
          <Button 
            variant="inline-link" 
            onClick={() => setShowTips(!showTips)}
          >
            {showTips ? "Hide details" : "Show details"}
          </Button>
        }
      >
        If using Excel files, ensure they are saved as standard Excel Workbook (.xlsx) and not Strict Open XML Spreadsheet.
      </Alert>

      {showTips && (
        <SpaceBetween direction="vertical" size="s">
          <Container>
            <SpaceBetween direction="vertical" size="xs">
              <Header
                variant="h3"
                actions={
                  <Button
                    variant="inline-icon"
                    iconName={showFormatDetails ? "angle-down" : "angle-right"}
                    onClick={() => setShowFormatDetails(!showFormatDetails)}
                  />
                }
              >
                How to check and fix Excel file format
              </Header>
              
              {showFormatDetails && (
                <ol>
                  <li>Open your Excel file</li>
                  <li>Click File → Save As</li>
                  <li>Under "Save as type", ensure "Excel Workbook (*.xlsx)" is selected</li>
                  <li>If "Strict Open XML Spreadsheet (*.xlsx)" is selected, change it to "Excel Workbook (*.xlsx)"</li>
                  <li>Save the file and try uploading again</li>
                </ol>
              )}
            </SpaceBetween>
          </Container>

          <Container>
            <SpaceBetween direction="vertical" size="xs">
              <Header
                variant="h3"
                actions={
                  <Button
                    variant="inline-icon"
                    iconName={showCommonIssues ? "angle-down" : "angle-right"}
                    onClick={() => setShowCommonIssues(!showCommonIssues)}
                  />
                }
              >
                Common issues with Excel files
              </Header>
              
              {showCommonIssues && (
                <ul>
                  <li>Files saved in "Strict Open XML" format may fail to load properly</li>
                  <li>Some older Excel versions may default to this format</li>
                  <li>The file extension will be .xlsx in both cases, making it hard to identify visually</li>
                  <li>Converting to standard Excel Workbook format typically resolves loading issues</li>
                </ul>
              )}
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      )}
    </Container>
  );
};

export default FileFormatGuidance;